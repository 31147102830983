<template>
    <ion-menu-toggle auto-hide="false">
        <ion-item
            router-direction="root"
            @click="router.replace(p.url)"
            lines="none"
            :class="{ selected: props.selected }"
            button
        >
            <VIcon slot="start" :icon="page.icon" class="icon"/>
            <ion-label>{{ page.title }}</ion-label>
        </ion-item>
    </ion-menu-toggle>
</template>

<script setup>
import { computed } from "vue";
import { alert as alertIcon } from "ionicons/icons";
/*** COMPONENTS ***/
import { IonMenuToggle, IonItem, IonLabel } from "@ionic/vue";
import VIcon from "@/components/VIcon.vue";
import { useRouter } from "vue-router";

const router = useRouter();

const ERROR_ENTRY = {
    url: "#",
    title: "Empty",
    icon: alertIcon,
};
// eslint-disable-next-line
const props = defineProps({
    // An object containing all keys mentioned in ERROR_ENTRY
    page: {
        type: Object,
        required: true,
    },
    selected: {
        type: Boolean,
        default: false,
    },
});
const p = computed(() => props?.page || ERROR_ENTRY);
</script>

<style scoped>
ion-menu.md ion-item {
    --padding-start: 10px;
    --padding-end: 10px;
    border-radius: 4px;
}
ion-menu.md ion-item .icon {
    color: #616e7e;
}
ion-menu.md ion-item ion-label {
    font-weight: 500;
}
ion-menu.md ion-item.selected {
    --background: rgba(var(--ion-color-primary-rgb), 0.05);
}
ion-menu.md ion-item.selected .icon {
    color: var(--ion-color-primary);
}

ion-menu.ios ion-item {
    --padding-start: 16px;
    --padding-end: 16px;
    --min-height: 50px;
}
ion-menu.ios ion-item .icon {
    font-size: 24px;
    color: #73849a;
}
ion-menu.ios ion-item.selected .icon {
    color: var(--ion-color-primary);
}

ion-item.selected {
    --color: var(--ion-color-primary);
}
</style>
