<template>
    <ion-app>
        <ion-split-pane content-id="main-content" :disabled="hideSidebar">
            <ion-menu content-id="main-content" type="overlay">
                <ion-content>
                    <ion-list class="border-b-2 border-medium-500">
                        <ion-list-header class="mb-5 font-bold text-2xl">
                            <ion-thumbnail
                                router-link="/start"
                                class="cursor-pointer"
                            >
                                <ion-img
                                    src="/img/icons/apple-touch-icon-60x60.png"
                                />
                            </ion-thumbnail>
                            <ion-label
                                router-link="/start"
                                class="ml-2 cursor-pointer"
                                >{{ pageTitle }}</ion-label
                            >
                        </ion-list-header>
                        <sidebar-menu-entry
                            :page="startPage"
                            :selected="currUrl == startPage.url"
                        />
                    </ion-list>

                    <ion-list
                        v-if="favoritePages.length > 0"
                        class="border-b-2 border-medium-500"
                    >
                        <div v-for="page in favoritePages" :key="page.name">
                            <sidebar-menu-entry
                                :page="page"
                                :selected="currUrl == page.url && !page.broken"
                            />
                        </div>
                    </ion-list>

                    <ion-list
                        v-if="schoolPages.length > 1"
                        class="border-b-2 border-medium-500"
                    >
                        <sidebar-menu-entry
                            v-for="page in schoolPages"
                            :key="page.title"
                            :page="page"
                            :selected="currUrl.startsWith(page.url)"
                        />
                    </ion-list>
                    <ion-list
                        v-else-if="schoolPages.length == 1"
                        class="border-b-2 border-medium-500"
                    >
                        <sidebar-menu-entry
                            v-for="page in entityTypePages"
                            :key="page.title"
                            :page="page"
                            :selected="currUrl.startsWith(page.url)"
                        />
                    </ion-list>
                    <ion-list>
                        <sidebar-menu-entry
                            :page="settingsPage"
                            :selected="currUrl.startsWith(settingsPage.url)"
                        />
                    </ion-list>
                </ion-content>
            </ion-menu>
            <ion-router-outlet id="main-content"></ion-router-outlet>
        </ion-split-pane>
    </ion-app>
</template>

<script setup>
import { computed, inject } from "vue";
import { useRoute } from "vue-router";
import { useLib } from "@/composables/lib";
import {
    home as homeIcon,
    business as schoolIcon,
    bookmark as bookmarkIcon 
} from "ionicons/icons";
/*** COMPONENTS ***/
import {
    IonApp,
    IonContent,
    IonList,
    IonListHeader,
    IonMenu,
    IonRouterOutlet,
    IonSplitPane,
    IonImg,
    IonThumbnail,
    IonLabel,
} from "@ionic/vue";
import SidebarMenuEntry from "@/components/SidebarMenuEntry.vue";

const route = useRoute();
const { ENTITY_TYPES, toSchoolsPage } = useLib();
const { t } = inject("i18n");
const settings = inject("settings");

const pageTitle = "plan.schule";
const currUrl = computed(() => route?.path);

const settingsPage = computed(() => ({
    title: t("Settings"),
    url: "/settings",
    icon: "fas fa-wrench",
}));

const startPage = {
    title: t("Start"),
    url: "/start",
    icon: homeIcon,
};

const schoolPages = computed(() => {
    return Array.from(settings.value.schools.keys()).map((name) => {
        return {
            title: name,
            url: toSchoolsPage(name),
            icon: schoolIcon,
        };
    });
});

const favoritePages = computed(() => {
    // Start with the favorite keys, which are used for sorting
    let keys = Array.from(settings.value.favoritesMap.keys());
    keys.sort();
    return keys.map((key) => {
        const fav = settings.value.favoritesMap.get(key);
        const broken = fav.school == undefined || fav.isEmpty();
        return {
            title: fav.name,
            url: fav.link(key),
            broken,
            icon: bookmarkIcon,
        };
    });
});

const entityTypePages = computed(() => {
    if (schoolPages.value.length != 1) {
        return [];
    }
    const school = schoolPages.value[0];
    // There is only one school, take that
    const schoolRole = settings.value.schools.values().next().value.kind;
    return (
        ENTITY_TYPES
            // Allow all types for 'teacher's and allow only groups for 'student's!
            .filter((type) => schoolRole != "student" || type.key == "groups")
            // Map them to something that is easily displayed
            .map((type) => ({
                title: t(type.i18nKey, 2),
                url: `${school.url}/${type.key}`,
                icon: type.icon,
            }))
    );
});

const hideSidebar = computed(() => {
    return currUrl.value.startsWith("/subs");
});

// TODO: This is just a fix to remove the old service worker
navigator?.serviceWorker?.getRegistrations()?.then(function (registrations) {
    for (let registration of registrations) {
        registration.unregister();
    }
});
</script>

<style scoped>
ion-menu ion-content {
    --background: var(--ion-item-background, var(--ion-background-color, #fff));
}

ion-menu.md ion-content {
    --padding-start: 8px;
    --padding-end: 8px;
    --padding-bottom: 20px;
}

ion-menu.md ion-list {
    padding: 20px 0;
}

ion-menu.md ion-note {
    margin-bottom: 30px;
}

ion-menu.md ion-list-header,
ion-menu.md ion-note {
    padding-left: 10px;
}

ion-menu.md ion-list#favorites-list ion-list-header {
    font-size: 22px;
    font-weight: 600;

    min-height: 20px;
}

ion-menu.ios ion-content {
    --padding-bottom: 20px;
}

ion-menu.ios ion-list {
    padding: 20px 0 0 0;
}

ion-menu.ios ion-note {
    line-height: 24px;
    margin-bottom: 20px;
}

ion-menu.ios ion-list#inbox-list ion-list-header {
    margin-bottom: 8px;
}

ion-menu.ios ion-list-header,
ion-menu.ios ion-note {
    padding-left: 16px;
    padding-right: 16px;
}

ion-menu.ios ion-note {
    margin-bottom: 8px;
}

ion-note {
    display: inline-block;
    font-size: 16px;

    color: var(--ion-color-medium-shade);
}
</style>
