// https://capacitorjs.com/docs/apis/preferences

import { Preferences } from "@capacitor/preferences";
import dayjs from "dayjs";

const CURRENT_STORAGE_VERSION = 3;

/* Convert the given API path and parameters to a storage key
   - key: Unique key used as prefix
   - params: Possible parameter for the key
   - token: School token */
const toStorageKey = (key, params) => {
    let paramStr = JSON.stringify(params);
    return `${key}?${paramStr}`;
};

/* Reviver function to handle ES6 Maps.
   See (https://stackoverflow.com/a/56150320) */
const reviver = (key, value) => {
    if (typeof value === "object" && value !== null) {
        if (value.dataType === "Map") {
            return new Map(value.value);
        }
    }
    return value;
};

/**
 * Check wether the given date is already expired
 * @param {number} timestamp - A unix timestamp
 * @returns {boolean}
 */
const isExpired = (timestamp) => dayjs().isAfter(dayjs.unix(timestamp));

/* Replacer function to handle ES6 Maps.
   See (https://stackoverflow.com/a/56150320) */
const replacer = (key, value) => {
    if (value instanceof Map) {
        return {
            dataType: "Map",
            value: Array.from(value.entries()),
        };
    } else {
        return value;
    }
};

/* Insert a key value pair into the storage.
   - key: Unique key used as a prefix for the derived key
   - value: value to be stored, will be serialized
   - params: Possible parameters for the derived key */
const set = (key, value, params = {}) => {
    // Neatly pack the data and add our current version as well as an expiration date
    const packed = {
        value,
        storage_version: CURRENT_STORAGE_VERSION,
        // TODO: Make expiration date configurable
        exp: dayjs().add(2, "weeks").unix(),
    };
    return Preferences.set({
        key: toStorageKey(key, params),
        value: JSON.stringify(packed, replacer),
    });
};

/* Retrieve a value from the storage.
   - key: Unique key used as a prefix for the derived key
   - params: Possible parameters for the derived key
   - isVolatile: Wether the requested data is volatile. If this is set
                 to true, the data will be dropped if there's a version mismatch or the value expired.
                 In this case, the data will not be returned. */
const get = (key, params = {}, isVolatile = false) => {
    return Preferences.get({
        key: toStorageKey(key, params),
    }).then((resp) => {
        const parsed = JSON.parse(resp.value, reviver);
        // If this is volatile data and there is no version match, delete
        // the data from the store and return null
        if (isVolatile && parsed?.storage_version != CURRENT_STORAGE_VERSION) {
            Preferences.remove({ key: toStorageKey(key, params) });
            return null;
        }
        // If this is volatile and the expiration date has passed, delete
        // the data from the store and return null
        if (isVolatile && (parsed?.exp == undefined || isExpired(parsed.exp))) {
            Preferences.remove({ key: toStorageKey(key, params) });
            return null
        }
        return parsed?.value;
    });
};

const clear = () => {
    return Preferences.clear();
};

const cleanInvalid = async () => {
    const keys = await Preferences.keys();
    // We must not delete the settings in the store, so let's manually skip that
    // TODO: Don't manually skip one key, yikes!
    const settingsKey = toStorageKey('settings', {});
    for (const key of keys.keys) {
        if (key != settingsKey) {
            // This works around our own `get`-method because we already got our key
            const raw_value = await Preferences.get({ key });
            const value = JSON.parse(raw_value.value, reviver);
            // Check the expiration date and storage version
            if (value?.storage_version != CURRENT_STORAGE_VERSION || value?.exp == undefined || isExpired(value.exp)) {
                await Preferences.remove({ key });
                console.log("Dropped old cache entry", key)
            }
        }
    }
};

export function useStore() {
    return { set, get, clear, cleanInvalid };
}
