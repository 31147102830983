"use strict";

import { useLib } from "@/composables/lib";

const { ENTITY_TYPES, SUCCESS, ERROR } = useLib();

const typeKeys = ENTITY_TYPES.map((type) => type.key);

/* A favorite containing different entities but always only entities of one school! */
export class Favorite {
    // Use named parameters
    constructor({
        name = "",
        teachers = [],
        locations = [],
        groups = [],
        school = undefined,
    } = {}) {
        this.name = name;
        this.school = school;
        this.teachers = teachers;
        this.locations = locations;
        this.groups = groups;
    }

    static from(json) {
        return Object.assign(new Favorite(), json);
    }

    /* Update an entity on this favorite
     - entityType: Type of the entity (see ENTITY_TYPES)
     - entity: Name of the entity (e.g. BRT)
     - shouldAdd: Should the entity be added (true) or removed (false)? */
    setEntity(entityType, entity, shouldAdd) {
        if (!typeKeys.includes(entityType)) {
            console.warn(`Invalid entityType: ${entityType}`);
            return ERROR;
        }
        // Only do anything if the current state is incorrect
        let idx = this[entityType].indexOf(entity);
        if (idx != -1 && !shouldAdd) {
            this[entityType].splice(idx, 1);
        } else if (idx == -1 && shouldAdd) {
            this[entityType].push(entity);
        }
        return SUCCESS;
    }

    /* Number of entities enabled for this favorite
     - entityType: Type used as a filter, can be omitted */
    countEntities(entityType = undefined) {
        if (typeKeys.includes(entityType)) {
            return this[entityType].length;
        } else {
            return (
                this.teachers.length +
                this.locations.length +
                this.groups.length
            );
        }
    }

    /* Assemble the parameter object represented by this favorite */
    assembleParams(settings) {
        if (this.isEmpty()) {
            throw new Error("Cannot assemble params for empty favorite");
        }
        let params = {};
        const toParams = (l) => l.join(",");
        const { token } = settings.schools.get(this.school);
        params["secret"] = token;
        if (this.teachers.length != 0) {
            params["teacher"] = toParams(this.teachers);
        }
        if (this.groups.length != 0) {
            params["group"] = toParams(this.groups);
        }
        if (this.locations.length != 0) {
            params["location"] = toParams(this.locations);
        }
        return params;
    }

    linkToDetails(id, tab = "calendar") {
        return `/fav/${encodeURIComponent(id)}/${tab}`;
    }

    linkToSettings(id) {
        return `/settings/addFavorite?favID=${encodeURIComponent(id)}`;
    }

    link(id, tab = "calendar") {
        const broken = this.school == undefined || this.isEmpty();
        if (broken) {
            return this.linkToSettings(id);
        } else {
            return this.linkToDetails(id, tab);
        }
    }

    /* Is there any entity? */
    isEmpty() {
        return (
            this.teachers.length == 0 &&
            this.locations.length == 0 &&
            this.groups.length == 0
        );
    }

    /* Update the selected school for this favorite */
    setSchool(newSchool) {
        // Make sure not to delete anything needlessly
        if (this.school != newSchool) {
            this.school = newSchool;
            this.teachers = [];
            this.locations = [];
            this.groups = [];
        }
    }

    /* Get the selected school */
    getSchool() {
        return this.school;
    }
}
