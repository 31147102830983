"use strict";

import { ref, watch, computed } from "vue";
import { useLib } from "@/composables/lib";
import { useStore } from "@/composables/storage";
import { Favorite } from "@/composables/favorite";
import { useTask } from "vue-concurrency";
import {PushNotifications} from "@capacitor/push-notifications";
import {FCM} from "@capacitor-community/fcm";
import {Capacitor} from "@capacitor/core";

const { updateLocale, SUCCESS, ERROR, UPDATED, CREATED } = useLib();
const store = useStore();

// Key for storage
const SETTINGS_KEY = "settings";
// Unique settings version to prevent errors
const VERSION = 4;

const guessLocale = () => {
    // TODO: Make generic over LOCALES
    if (navigator?.language) {
        if (navigator.language.startsWith("de")) return "de-DE";
        if (navigator.language.startsWith("en")) return "en-US";
    }
    if (navigator?.languages) {
        for (const lang of navigator.languages) {
            if (lang.startsWith("de")) return "de-DE";
            if (lang.startsWith("en")) return "en-US";
        }
    }
    // TODO: Try other methods
    return "en-US";
};

// Default settings, if none exist yet
const DEFAULT_SETTINGS = new Map([
    // The current version of this settings object, used for migrations
    ["version", VERSION],
    // The list of user favorites, these are `Favorite` objects
    ["favorites", new Map()],
    // A map of school names to schools
    ["schools", new Map()],
    // The selected language
    ["lang", guessLocale()],
]);

// The actual settings object
// A map of key-value-pairs containing:
// - favorites: A map of favorites
// - schools: A map (name -> { token, kind }) of schools
//            where the token is a secret string and the
//            kind is either 'teacher' or 'student'
// - lang: The currently selected language
// - version: The settings version number
const settings = ref(new Map());
// Has the settings object been initialized.
// Nice if you can't .then on settingsLoaded.
const initialized = ref(false);

const migrateSettings = (settings) => {
    const version = settings?.get?.("version");
    // If settings is undefined or has no version, replace it
    // If the settings map is empty, replace it
    if (!version || settings.size == 0) {
        console.warn("Settings empty or version missing, replacing");
        return DEFAULT_SETTINGS;
    }
    // This is where migrations may be added later on
    if (version == 1) {
        console.log("Migrating settings 1 -> 2");
        // Add the 'lang' settings and remove 'selectedSchool'
        settings.delete("selectedSchool");
        settings.set("lang", "en");
        settings.set("version", 2);
        return migrateSettings(settings);
    } else if (version == 2) {
        console.log("Migrating settings 2 -> 3");
        // Change the type of `favorites` from list to map
        // This was done to allow sorting favorites by the key
        let counter = 0;
        let newFavorites = new Map();
        for (let fav of settings.get("favorites")) {
            newFavorites.set(counter, fav);
            counter += 1;
        }
        settings.set("favorites", newFavorites);
        settings.set("version", 3);
        return migrateSettings(settings);
    } else if (version == 3) {
        // Change the schools from
        // `name` -> `token` to
        // `name` -> `{ token, kind }`
        // All kind-less schools will be given the `student` kind!
        let newSchools = new Map();
        for (let [name, token] of settings.get("schools")) {
            newSchools.set(name, { token, kind: "student" });
        }
        settings.set("schools", newSchools);
        settings.set("version", 4);
        return migrateSettings(settings);
    } else if (version != VERSION) {
        // Migrations will happen before this,
        // this will just be the `else` clause
        console.warn("Settings version invalid, cannot migrate, replacing");
        return DEFAULT_SETTINGS;
    }
    // All seems fine, just return the original
    return settings;
};

// A promise tracking the loading state of the settings object
// Initial loading of the stored settings
const settingsLoaded = store.get(SETTINGS_KEY).then((newS) => {
    // Make sure, that the settings are valid regarding our versioning
    newS = migrateSettings(newS);
    // Make sure Favorites are actually the correct favorite class objects
    const favorites = newS.get("favorites");
    for (let key of favorites.keys()) {
        favorites.set(key, Favorite.from(favorites.get(key)));
    }

    settings.value = newS;
    // Make sure to initialize dayjs locale
    updateLocale(getLang());
    initialized.value = true;
    console.log("Loaded settings!", newS);
    return Promise.resolve(settings.value);
});

// Watch the settings for updates and store the new
// settings. Not sure how this handles concurrent access,
// but it's probably not important.
watch(
    settings,
    async (newS) => {
        // Update the storage
        console.log("Updating changed settings", newS);
        await store.set(SETTINGS_KEY, newS);
    },
    { deep: true }
);

/* Get a setting by key.
   Returns orElse if the key is not defined */
const getKey = (key, orElse = undefined) => {
    if (settings.value && settings.value.has(key)) {
        return settings.value.get(key);
    }
    return orElse;
};
const getFavorites = () => getKey("favorites", []);
const getSchools = () => getKey("schools", new Map());
const getLang = () => getKey("lang", "en-US");
const getNotificationState = () => getKey("notifications", false);
const favByName = (name) => {
    const favs = Array.from(getFavorites().values());
    return favs.find((f) => f.name == name);
};
const favByID = (id) => getFavorites().get(parseInt(id));

/* Remove a favorite by name */
const removeFavorite = async (key) => {
    if (initialized.value) {
        if (getNotificationState()) {
            await unsubscribeFavorite(key);
        }
        if (!getFavorites().delete(key)) {
            console.warn("Tried to remove favorite, but it doesn't exist");
            return ERROR;
        }
        return SUCCESS;
    }
    return ERROR;
};

/* Create an empty favorite w/o any selection */
const createFavorite = (conf = {}) => {
    if (initialized.value) {
        const schools = getSchools();
        if (schools?.size == 1) {
            // There is just one school, use that as default
            conf.school = schools.keys().next().value;
        }
        const existingKeys = Array.from(getFavorites().keys());
        const maxKey = Math.max(0, ...existingKeys);
        const newKey = maxKey + 1;
        getFavorites().set(newKey, new Favorite(conf));
        return newKey;
    }
};

/* Instead of creating a new favorite, just add this one */
const addFavorite = async (fav) => {
    if (initialized.value) {
        const existingKeys = Array.from(getFavorites().keys());
        const maxKey = Math.max(1, ...existingKeys);
        const newKey = maxKey + 1;
        getFavorites().set(newKey, fav);
        if (getNotificationState()) {
            await subscribeFavorite(newKey);
        }
        return SUCCESS;
    }
    return ERROR;
};

/* Add the given school name with the given token.
   Returns a status code that can be used to inform the user. */
const addSchool = (name, token, kind = "student") => {
    if (initialized.value) {
        const schools = getSchools();
        let status;
        if (schools.has(name)) {
            status = UPDATED;
        } else {
            status = CREATED;
        }
        schools.set(name, { token, kind });
        return status;
    }
    return ERROR;
};

const toggleNotifications = (state) => {
    settings.value.set("notifications", state);
};

const setLang = (newLang) => {
    if (initialized.value) {
        updateLocale(newLang);
        settings.value.set("lang", newLang);
    }
};

const removeSchool = async (name) => {
    if (initialized.value) {
        const schools = getSchools();
        if (schools.has(name)) {
            // We need to unset the school that was just deleted from all
            // favorites referencing it!
            for (const fav of getFavorites()) {
                if (getNotificationState()) {
                    await unsubscribeFavorite(fav[0]);
                }
                if (fav.school == name) {
                    fav.school = undefined;
                }
            }
            schools.delete(name);
            return SUCCESS;
        } else {
            console.warn("Should remove school but it doesn't exist");
            return ERROR;
        }
    }
    return ERROR;
};

/* Update the order of the favorites according to `newKeys`. */
const reorderFavorites = (newKeys) => {
    const oldFavs = getFavorites();
    const newFavs = newKeys.map((key, idx) => [idx, oldFavs.get(key)]);
    settings.value.set("favorites", new Map(newFavs));
};

/* Set all schools with the given `token` to the given `role` */
const validateTokenRole = (token, role) => {
    for (const [name, school] of getSchools()) {
        if (token === school.token && school.kind != role) {
            console.log(
                `Fixing mismatch in token role for school ${name}: ${school.kind} -> ${role}`
            );
            school.kind = role;
        }
    }
};

/* Rename a school from `oldName` to `newName`.
   This makes sures all favorites stay valid! */
const renameSchool = (oldName, newName) => {
    const schools = getSchools();
    // Make sure the school exists
    if (schools.has(oldName)) {
        // Base changes
        const school = schools.get(oldName);
        schools.delete(oldName);
        schools.set(newName, school);
        // Now make sure our favorites stay valid
        getFavorites().forEach((fav) => {
            if (fav.school == oldName) {
                fav.school = newName;
            }
        });
    }
};

// Push Notifications
function getTopicString(secret, group) {
    return getLang() + "_" + (secret + "_" + group).replace(/[^a-zA-Z0-9-_.~%]/, '_');
}

const isPushNotificationsAvailable = () => Capacitor.isPluginAvailable('PushNotifications');

const registerNotifications = async () => {
    if (!isPushNotificationsAvailable() || (await PushNotifications.checkPermissions()).receive === 'prompt') return;
    let permStatus = await PushNotifications.checkPermissions();

    if (permStatus.receive === 'prompt') {
        permStatus = await PushNotifications.requestPermissions();
    }

    if (permStatus.receive !== 'granted') {
        return ERROR;
    }

    await PushNotifications.register();
    // Add Push Notifications for all Favorites
    let favoriteKeys = Array.from(getFavorites().keys());
    favoriteKeys.sort();
    for(const favorite of favoriteKeys) {
        await subscribeFavorite(favorite);
    }

    // Get FCM token instead of the APN one returned by Capacitor
    let status;
    await FCM.getToken()
        .then((r) => {status = SUCCESS})
        .catch((err) => {status = ERROR});

    // Enable the auto initialization of the library
    await FCM.setAutoInit({ enabled: true });

    toggleNotifications(true);
    return status;
}

const unregisterNotifications = async () => {
    if (!isPushNotificationsAvailable() || (await PushNotifications.checkPermissions()).receive === 'prompt') return;
    // Enable the auto initialization of the library
    toggleNotifications(false);
    await PushNotifications.unregister();
    await FCM.setAutoInit({ enabled: false });
    await FCM.deleteInstance();
};

async function subscribeFavorite(favoriteKey) {
    if (!isPushNotificationsAvailable() || (await PushNotifications.checkPermissions()).receive === 'prompt') return;
    let favoriteFor = getFavorites().get(favoriteKey);
    // If the favorite is not connected to a school or the school doesn't exist, return
    if (typeof favoriteFor["school"] === "undefined" || typeof getSchools().get(favoriteFor["school"]) === "undefined") return;
    const keys = ["teachers", "locations", "groups"];
    for(const key of keys) {
        for(const elem of favoriteFor[key]) {
            let topic_str = getTopicString(getSchools().get(favoriteFor["school"])["token"], elem);
            FCM.subscribeTo({ topic: topic_str })
                .catch((err) => console.error(err));
        }
    }
    let topic_str = getLang() + "_" + getSchools().get(favoriteFor["school"])["token"];
    FCM.subscribeTo({ topic: topic_str })
        .catch((err) => console.error(err));
}

async function unsubscribeFavorite(favoriteKey) {
    if (!isPushNotificationsAvailable() || (await PushNotifications.checkPermissions()).receive === 'prompt') return;
    let favoriteFor = getFavorites().get(favoriteKey);
    const keys = ["teachers", "locations", "groups"];
    for(const key of keys) {
        for(const elem of favoriteFor[key]) {
            let topic_str = getTopicString(getSchools().get(favoriteFor.school)["token"], elem);
            FCM.unsubscribeFrom({ topic: topic_str })
                .catch((err) => console.error(err));
        }
    }
    let tmpKeys = Array.from(getFavorites().keys());
    tmpKeys.sort();
    // Try to find another favorite of school (If found, don't unsubscribe from school)
    const tokenMatchFound = tmpKeys.some(
        tmpFavoriteKey => JSON.stringify(tmpFavoriteKey) !== JSON.stringify(favoriteKey) &&
        getSchools()[getFavorites().get(tmpFavoriteKey)["school"]]["token"] === getSchools().get(favoriteFor.school)["token"]
    );
    if (!tokenMatchFound) {
        let topic_str = getLang() + "_" + getSchools().get(favoriteFor["school"])["token"];
        FCM.unsubscribeFrom({ topic: topic_str })
            .catch((err) => console.error(err));
    }
}

const useSettingsLoader = () =>
    useTask(function* () {
        yield settingsLoaded;
        return {
            favoritesMap: computed(getFavorites),
            favorites: computed(() => {
                const favs = getFavorites().values();
                return Array.from(favs);
            }),
            schools: computed(getSchools),
            removeFavorite,
            createFavorite,
            favByName,
            favByID,
            addSchool,
            removeSchool,
            getLang,
            setLang,
            reorderFavorites,
            addFavorite,
            validateTokenRole,
            renameSchool,
            getNotificationState,
            isPushNotificationsAvailable,
            registerNotifications,
            unregisterNotifications
        };
    });

export { useSettingsLoader };
