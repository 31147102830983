import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createI18n } from "vue-i18n";
import { useSettingsLoader } from "@/composables/settings.js";
import { useStore } from "@/composables/storage";

import { IonicVue } from "@ionic/vue";

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import "./theme/core.css";

/* Tailwind CSS */
import "./assets/styles/tailwind.css";

/* PWA ServiceWorker */
// import './registerServiceWorker'

const messages = require("../i18n.json");
const settings = useSettingsLoader().perform();

const i18n = createI18n({
    legacy: false,
    messages,
});

// Delete old cache entries
useStore().cleanInvalid().then(() => console.log("Successfully removed old cache entries"));

// Update the global locale as soon as the settings
// finished loading
settings.then((s) => {
    i18n.global.locale.value = s.getLang();
});

/* init Vue */
const app = createApp(App).use(IonicVue).use(i18n).use(router);

app.config.errorHandler = (err, instance, info) => {
    console.error("Oh my, an error!", err, instance, info);
};

/* Injections */
app.provide("settings", settings);
app.provide("i18n", i18n.global);

/* start */
const routerReady = router.isReady();
Promise.allSettled([routerReady, settings]).then(() => {
    app.mount("#app");
});
