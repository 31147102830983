import dayjs from "dayjs";
import weekOfYear from "dayjs/plugin/weekOfYear";
import isoWeek from "dayjs/plugin/isoWeek";
import localizedFormat from "dayjs/plugin/localizedFormat";
import locale_de from "dayjs/locale/de";

import { alertController, toastController } from "@ionic/vue";
import { Capacitor } from "@capacitor/core";

// TODO: A lot of code is assuming, that we all agree that sundays are the 0th day of the week..
dayjs.extend(weekOfYear);
dayjs.extend(isoWeek);
dayjs.extend(localizedFormat);
// For calendar acting the same in every language, strings are handled anyway by i18n
dayjs.locale(locale_de);

// Define some common symbols for communication
const SUCCESS = Symbol("SUCCESS");
const CREATED = Symbol("CREATED");
const UPDATED = Symbol("UPDATED");
const ERROR = Symbol("ERROR");

const WEEK_DAYS = {
    1: "Mon",
    2: "Tue",
    3: "Wed",
    4: "Thu",
    5: "Fri",
};

// TODO: Use correct icons
const ENTITY_TYPES = [
    { key: "teachers", i18nKey: "Teacher", icon: "fas fa-chalkboard-teacher" },
    { key: "groups", i18nKey: "Group", icon: "fi-group" },
    { key: "locations", i18nKey: "Location", icon: "fas fa-chalkboard" },
];

/* Counter for unique IDs */
let uuidCounter = 0;

/* Get the start day of this week.
   Assuming weeks start on mondays! */
const startOfWeek = (date = dayjs()) => {
    return date.startOf("week");
};

const datesOfWorkWeek = (initial = startOfWeek()) => {
    const offsets = [0, 1, 2, 3, 4];
    return offsets.map((offset) => initial.add(offset, "day"));
};

/* Get the weekday of a string date */
const weekdayOf = (dateStr) => dayjs(dateStr).day();
/* Get the first weekday of the week */
const firstDayOfWeek = () => startOfWeek().day();
/* Is the given date string a normal work day */
const isWorkDay = (dateStr) => [1, 2, 3, 4, 5].includes(weekdayOf(dateStr));
/* Parse the given date string into a dayjs object */
const parseDate = (dateStr) => dayjs(dateStr);
/**
 * Parse the given date string into a dayjs object.
 * @param {string|'today'|'tomorrow'} - The date string or 'today'|'tomorrow'
 * @returns {dayjs} - The parsed date
 */
const parseDateExt = (dateStr) => {
    if (dateStr === "today") {
        return dayjs();
    } else if (dateStr === "tomorrow") {
        return dayjs().add(1, "day");
    } else {
        return dayjs(dateStr);
    }
};
/* Get the week number of the given date */
const weekOf = (date) => date.isoWeek();
/* Format the given dateStr in long day format */
const dateFormatLong = (dateStr) => {
    const parsed = dayjs(dateStr);
    return parsed.format("dddd, LL");
};
/* Hacky way to convert a WEEK_DAYS number into the localized name of the day */
const toWeekdayName = (nr) => dayjs().day(nr).format("dddd");
/* Update the locale globally */
const updateLocale = (langStr) => {
    // Update the lang attribute of the html tag for the browser
    document?.documentElement?.setAttribute?.("lang", langStr);
};
/* Are these arrays equal or both undefined? */
const isSameArrayOrUndef = (a, b) => {
    if (a === undefined && b === undefined) return true;
    return (
        Array.isArray(a) &&
        Array.isArray(b) &&
        a.length === b.length &&
        a.every((val, index) => val === b[index])
    );
};
/* Are these two lessons almost the same? Only differing in the nr (hour)? */
const isDoubleLesson = (la, lb) => {
    if ("placeHolder" in la && "placeHolder" in lb) {
        return true;
    }
    return (
        isSameArrayOrUndef(la.week_types, lb.week_types) &&
        la.weekday == lb.weekday &&
        la.group == lb.group &&
        isSameArrayOrUndef(la.meta_groups, lb.meta_groups) &&
        la.subject == lb.subject &&
        isSameArrayOrUndef(la.locations, lb.locations) &&
        isSameArrayOrUndef(la.teachers, lb.teachers) &&
        la.note == lb.note
    );
};

const isNative = Capacitor.isNative;

const sendToast = async ({ msg, col = "primary", duration = 1000, position = "bottom"} = {}) => {
    const toast = await toastController.create({
        message: msg,
        duration,
        color: col,
        position: position,
    });
    return toast.present();
};

const createUserFeedback = (status, optionMap) => {
    const option = optionMap[status];
    if (option) {
        return sendToast(option);
    }
};

const extractDetailsFromQR = (rawUrl) => {
    const base = process.env.VUE_APP_URL;
    if (rawUrl.startsWith(base)) {
        const url = new URL(rawUrl);
        const params = url.searchParams;
        return {
            name: params.get("name"),
            token: params.get("token"),
        };
    } else {
        return {};
    }
};

const routerGoBack = (router) => {
    // Go back if possible, if not, go to the landing page
    if (router.options.history?.state?.back) {
        router.back();
    } else {
        router.replace("/start");
    }
};
const goToStart = (router) => router.push("/start");

const toSchoolsPage = (name) => `/school/${encodeURIComponent(name)}`;
const toSchoolEntitiesPage = (name, type) =>
    `${toSchoolsPage(name)}/${encodeURIComponent(type)}`;

// To keep track of all the errors on the screen
let errorQueue = Array();

const toError = async (message) => {
    const modal = await alertController.create({
        header: "Error",
        message,
        buttons: ["Ok"],
    });

    // Push alert to the start of the queue (but only if its a different message 
    // than the already displayed alert)
    if (errorQueue.length > 0) {
        if (errorQueue.at(-1).message !== message) {
            errorQueue.unshift(modal)
        }
        else {
            // Has to be returned otherwise the await below will never resolve
            return;
        }
    }
    // If the array is empty always push the element
    else {
        errorQueue.unshift(modal)
    }

    // Present the alert if it is the first one in the queue after it got
    // created
    if (errorQueue.at(-1) === modal) {
        modal.present();
    }

    await modal.onWillDismiss();

    // It is guaranteed that the last element is the currently dismissed alert
    errorQueue.pop();

    // Present the next one (if there is one)
    if (errorQueue.length > 0) 
        errorQueue.at(-1).present();
};

const useLib = () => ({
    SUCCESS,
    UPDATED,
    ERROR,
    CREATED,
    WEEK_DAYS,
    ENTITY_TYPES,
    datesOfWorkWeek,
    firstDayOfWeek,
    parseDate,
    parseDateExt,
    startOfWeek,
    weekdayOf,
    weekOf,
    isWorkDay,
    dateFormatLong,
    updateLocale,
    isSameArrayOrUndef,
    isDoubleLesson,
    sendToast,
    isNative,
    createUserFeedback,
    extractDetailsFromQR,
    routerGoBack,
    toSchoolsPage,
    toSchoolEntitiesPage,
    goToStart,
    toError,
    toWeekdayName,
});

const useUUID = () => {
    let uuid = uuidCounter.toString();
    uuidCounter += 1;
    return uuid;
};

export { useLib, useUUID };
