import { createRouter, createWebHistory } from "@ionic/vue-router";
import { useStore } from "@/composables/storage";
import { App } from "@capacitor/app";

const store = useStore();

const routes = [
    {
        path: "",
        redirect: "/start",
    },
    {
        path: "/start",
        component: () => import("../views/LandingPage.vue"),
    },
    {
        path: "/school",
        redirect: "/start",
    },
    {
        path: "/school/:schoolName",
        component: () => import("../views/SchoolPage.vue"),
    },
    {
        path: "/school/:schoolName/:entityType",
        component: () => import("../views/EntitiesPage.vue"),
    },
    {
        path: "/school/:schoolName/:entityType/:entityId",
        redirect: (to) => `${to.path}/timetable`,
    },
    {
        path: "/school/:schoolName/:entityType/:entityID",
        component: () => import("../views/EntityDetailsPage.vue"),
        children: [
            {
                path: "calendar",
                component: () => import("../components/CalendarTab.vue"),
                props: true,
            },
            {
                path: "calendar-day",
                component: () => import("../components/CalendarDayTab.vue"),
                props: true,
            },
            {
                path: "sublist",
                component: () => import("../components/SubListTab.vue"),
                props: true,
            },
            {
                path: "schedule",
                component: () => import("../components/ScheduleTab.vue"),
                props: true,
            },
        ],
    },
    {
        path: "/settings",
        component: () => import("../views/SettingsPage.vue"),
    },
    {
        path: "/settings/addFavorite",
        component: () => import("../views/FavoritePage.vue"),
    },
    {
        path: "/fav",
        redirect: "/start",
    },
    {
        path: "/fav/:favID",
        component: () => import("../views/FavDetailsPage.vue"),
        children: [
            {
                path: "calendar",
                component: () => import("../components/CalendarTab.vue"),
                props: true,
            },
            {
                path: "calendar-day",
                component: () => import("../components/CalendarDayTab.vue"),
                props: true,
            },
            {
                path: "sublist",
                component: () => import("../components/SubListTab.vue"),
                props: true,
            },
            {
                path: "schedule",
                component: () => import("../components/ScheduleTab.vue"),
                props: true,
            },
        ],
    },
    {
        path: "/plan/school",
        component: () => import("../views/AddSchool.vue"),
    },
    {
        path: "/subs",
        component: () => import("../views/SubList.vue"),
    },
    {
        path: "/:pathMatch(.*)*",
        name: "NotFound",
        component: () => import("../views/NotFound.vue"),
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

const shouldRemember = (path) => {
    // Favorite pages
    return (
        path.startsWith("/fav") ||
        // School pages, but only if there's actual a table there
        // TODO: This can of course be cheated by naming your school 'timetable', real smart
        (path.startsWith("/school") && path.endsWith("/timetable")) ||
        (path.startsWith("/school") && path.endsWith("/substitutiontable"))
    );
};

// Listener for handling deep links
App.addListener("appUrlOpen", (event) => {
    const end = event.url.split(process.env.VUE_APP_URL).pop();
    if (end) {
        router.push(end);
    }
});

// Catch every new route and update our `lastTimeTable` variable
// `lastTimeTable` is used by the GoToLastTimeTable.vue SFC
// to redirect the user!
router.afterEach((to) => {
    const target = to.path;
    if (shouldRemember(target)) {
        store.set("lastTimeTable", target);
    }
});

export default router;
